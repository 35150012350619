<template>
    <modal ref="modalComentarioEntrega" titulo="Comentario" tamano="modal-lg" noCancelar noAceptar cerrar   >
        <div class="row mx-0 j-center">
            <div class="col-auto" />
            <div class="col-10">
                <p class="f-600 text-gris">
                    {{ _.get(data, 'titulo') }}
                </p>
                <div class="row mx-0 my-4">
                    {{ _.get(data, 'user_comentario') }}
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

    <script>
    export default {
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        toggle(){
            this.$refs.modalComentarioEntrega.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
</style>